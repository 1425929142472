<template>
  <div class="my-img">
    <Navbar />
    <div class="container " style="margin-top:100px;">
      <div>
        <b-row>
          <b-col>
            <h1>{{ $t("personaldata") }}</h1>
            <!-- <p
              class="mb-0 mt-0 pb-0 pt-0 my-text-success"
              style="margin-left: 8px !important"
            >
                Ota-ona ma'lumotlari 
            </p> -->
          </b-col>
        </b-row>
        <b-row style="margin-top: 32px">
          <b-col sm="12" md="9">
            <custom-select
              :label="$t('documenttype')"
              @input="ChangeIdentityDocument"
              v-model="filter.identitydocumentid"
              :options="IdentityDocumentList"
            ></custom-select>
          </b-col>

          <b-col sm="12" md="3" v-if="filter.identitydocumentid == 3">
            <custom-input
              placeholder="12345678901234"
              v-mask="'NNNNNNNNNNNNNN'"
              :label="$t('pinfl')"
              v-model="filter.pinfl"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row style="margin-top: 16px">
          <b-col md="3" sm="12">
            <custom-input
              v-if="
                filter.identitydocumentid != 4 && filter.identitydocumentid != 3
              "
              v-mask="'AA'"
              uppertext
              v-model="filter.docseries"
              placeholder="AA"
              :label="$t('documentseries')"
            ></custom-input>
            <custom-input
              v-if="
                filter.identitydocumentid == 4 || filter.identitydocumentid == 3
              "
              v-model="filter.docseries"
              placeholder="AA"
              :label="$t('documentseries')"
            ></custom-input>
            <span
              v-if="!filter.docseries"
              style="font-size: 12px; margin-left: 10px; color: red"
            >
              {{ $t("onlylatinletters") }}
            </span>
          </b-col>
          <b-col md="3" sm="12">
            <custom-input
              v-if="filter.identitydocumentid != 4"
              v-mask="'#######'"
              placeholder="7777777"
              v-model="filter.docnumber"
              :label="$t('documentnumber')"
            ></custom-input>
            <custom-input
              v-if="filter.identitydocumentid == 4"
              placeholder="7777777"
              v-model="filter.docnumber"
              :label="$t('documentnumber')"
            ></custom-input>
          </b-col>
          <b-col :md="3" sm="12">
            <custom-date-picker
              placeholder="00.00.0000"
              @keyup="BindValue"
              v-model="filter.dateofbirth"
              :label="$t('dateofbirth')"
            ></custom-date-picker>
          </b-col>
          <b-col sm="6" :md="3" v-if="filter.identitydocumentid == 4">
            <custom-select
              :label="$t('gender')"
              v-model="Parent.genderid"
              :options="GenderList"
            ></custom-select>
          </b-col>
          <b-col md="3" sm="12" v-if="filter.identitydocumentid != 4">
            <b-button
              block
              variant="outline-primary"
              size="lg"
              @click="SearchbyEGov"
            >
              <p class="m-0 p-0 p-1">
                <b-spinner v-if="SearchLoading" small></b-spinner>
                {{ $t("search") }}
              </p>
            </b-button>
          </b-col>
        </b-row>
        <b-row style="margin-top: 16px">
          <b-col sm="12" md="3">
            <custom-input
              :disabled="filter.identitydocumentid != 4"
              placeholder="12345678901234"
              v-mask="'##############'"
              v-model="Parent.pinfl"
              :label="$t('pinfl')"
            ></custom-input>
          </b-col>
          <b-col sm="12" md="9" v-if="filter.identitydocumentid != 4">
            <!-- <custom-input   placeholder="Иван Иванович Иванов" v-model="Parent.fullname" label="ФИО"></custom-input> -->
            <custom-input
              :disabled="filter.identitydocumentid != 4"
              v-model="Parent.fullname"
              :label="$t('FIO')"
            ></custom-input>
          </b-col>
          <b-col sm="12" :md="3" v-if="filter.identitydocumentid == 4">
            <custom-input
              :placeholder="$t('familyname')"
              v-model="Parent.familyname"
              :label="$t('familyname')"
            ></custom-input>
          </b-col>
          <b-col sm="12" :md="3" v-if="filter.identitydocumentid == 4">
            <custom-input
              :placeholder="$t('firstname')"
              v-model="Parent.firstname"
              :label="$t('firstname')"
            ></custom-input>
          </b-col>
          <b-col sm="12" :md="3" v-if="filter.identitydocumentid == 4">
            <custom-input
              :placeholder="$t('lastname')"
              v-model="Parent.lastname"
              :label="$t('lastname')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row style="margin-top: 16px">
          <b-col sm="12" md="3">
            <custom-date-picker
              :disabled="filter.identitydocumentid != 4"
              :label="$t('dateofissue')"
              placeholder="00.00.0000"
              v-model="Parent.dateofissue"
            ></custom-date-picker>
          </b-col>
          <b-col sm="12" md="9">
            <custom-input
              :disabled="filter.identitydocumentid != 4"
              placeholder=""
              v-model="Parent.issueorganization"
              :label="$t('issuer')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row
          v-if="!!PermanentAdress.oblastname && !!PermanentAdress.regionname"
          class="text-center info-text-live"
          style="margin-bottom: -20px; margin-top: 20px"
        >
          <b-col sm="12">
            <span style="color: #5168A0">
              {{ $t("addressofinternalaffairs") }}
            </span>
          </b-col>
        </b-row>
        <b-row
          v-if="!!PermanentAdress.oblastname && !!PermanentAdress.regionname"
          class="real-live-place"
        >
          <b-col>
            <p class="d-flex justify-content-between">
              <span>{{ $t("address") }}</span>
            </p>
          </b-col>
        </b-row>
        <b-row
          v-if="!!PermanentAdress.oblastname && !!PermanentAdress.regionname"
        >
          <b-col sm="12" md="6" style="margin-bottom: 16px">
            <!-- <custom-input    v-model="PermanentAdress.oblastname" disabled :label="$t('oblast')"></custom-input> -->
            <custom-label
              :label="$t('oblast')"
              :content="PermanentAdress.oblastname"
            />
          </b-col>
          <b-col sm="12" md="6" style="margin-bottom: 16px">
            <!-- <custom-input    v-model="PermanentAdress.regionname" disabled :label="$t('region')"></custom-input> -->
            <custom-label
              :label="$t('region')"
              :content="PermanentAdress.regionname"
            />
          </b-col>
          <b-col sm="12" md="12" style="margin-bottom: 16px">
            <!-- <custom-input   v-model="PermanentAdress.address" disabled :label="$t('address')"></custom-input> -->
            <custom-label
              :label="$t('address')"
              :content="PermanentAdress.address"
            />
          </b-col>
        </b-row>
        <div v-if="!!ActiveAdress.oblastname && !!ActiveAdress.regionname">
          <b-row class="real-live-place">
            <b-col>
              <p class="d-flex justify-content-between">
                <span
                  >{{ $t("templiveplace") }}
                  <span
                    >({{ ActiveAdress.datefrom }} -
                    {{ ActiveAdress.datetill }})</span
                  ></span
                >
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6" style="margin-bottom: 16px">
              <custom-label
                :label="$t('oblast')"
                :content="ActiveAdress.oblastname"
              />
            </b-col>
            <b-col sm="12" md="6" style="margin-bottom: 16px">
              <custom-label
                :label="$t('region')"
                :content="ActiveAdress.regionname"
              />
            </b-col>
            <b-col sm="12" md="12" style="margin-bottom: 16px">
              <custom-label
                :label="$t('address')"
                :content="ActiveAdress.address"
              />
            </b-col>
          </b-row>
        </div>
        <b-row v-if="filter.identitydocumentid == 4">
          <b-col sm="12" md="6" class="mt-3">
            <custom-select
              :label="$t('oblast')"
              v-model="Parent.oblastid"
              :options="OblastList"
              @input="ChangeOblast"
            ></custom-select>
          </b-col>
          <b-col sm="12" md="6" class="mt-3">
            <custom-select
              :label="$t('region')"
              v-model="Parent.regionid"
              :options="RegionList"
            ></custom-select>
          </b-col>
          <b-col sm="12" class="mt-3">
            <custom-input
              :placeholder="$t('address')"
              v-model="Parent.address"
              :label="$t('address')"
            ></custom-input>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 50px; margin-top: 16px">
          <b-col class="d-flex justify-content-end">
            <b-button variant="primary" @click="GotoApplication">
              <p class="m-0 p-0 p-1">
                <b-spinner v-if="SaveLoading" small></b-spinner>
                {{ $t("next") }}
              </p>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/navbar.vue";
import CustomSelect from "./components/CustomSelect.vue";
import CustomInput from "./components/CustomInput.vue";
import CustomDatePicker from "./components/CustomDatepicker.vue";
import CustomLabel from "./components/CustomLabel.vue";
import AccountService from "../services/account.service";
import PersonService from "../services/person.service";
import HelperService from "../services/helper.service";
export default {
  components: {
    Navbar,
    CustomSelect,
    CustomInput,
    CustomDatePicker,
    CustomLabel,
  },
  data() {
    return {
      filter: {
        docseries: "",
        docnumber: "",
        dateofbirth: "",
        identitydocumentid: 2,
        pinfl: "",
        kinshipdegreeid: 0,
      },
      IdentityDocumentList: [],
      Parent: {},
      SearchLoading: false,
      lang: localStorage.getItem("locale") || "uz_latn",

      KinShipDegreeList: [],
      PermanentAdress: {},
      ActiveAdress: {},
      SaveLoading: false,
      GenderList: [],
      OblastList: [],
      RegionList: [],
    };
  },
  created() {
    HelperService.GetAllIdentityDocument(true, this.lang).then((res) => {
      this.IdentityDocumentList = res.data.result;
    });
    HelperService.GetGenderList(this.lang).then((res) => {
      this.GenderList = res.data;
    });
  },
  methods: {
    makeToast(message, type) {
      this.$toast.open({
        message: message,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
    ChangeOblast() {
      if (!!this.Parent.oblastid) {
        HelperService.GetAllRegion(this.lang, this.Parent.oblastid).then(
          (res) => {
            this.RegionList = res.data;
          }
        );
      }
    },
    SearchbyEGov() {
      if (this.filter.identitydocumentid == 3) {
        if (
          this.filter.pinfl === "" ||
          this.filter.pinfl === null ||
          this.filter.pinfl === undefined ||
          this.filter.pinfl === 0
        ) {
          this.makeToast(this.$t("enterpinfl"), "error");
          return false;
        }
      }
      if (
        this.filter.docseries === "" ||
        this.filter.docseries === 0 ||
        this.filter.docseries === undefined ||
        this.filter.docseries === null
      ) {
        this.makeToast(this.$t("enterdocumentseries"), "error");
        return false;
      }
      if (
        this.filter.docnumber === "" ||
        this.filter.docnumber === 0 ||
        this.filter.docnumber === undefined ||
        this.filter.docnumber === null
      ) {
        this.makeToast(this.$t("enterdocumentnumber"), "error");
        return false;
      }
      if (
        this.filter.dateofbirth === "" ||
        this.filter.dateofbirth === 0 ||
        this.filter.dateofbirth === undefined ||
        this.filter.dateofbirth === null
      ) {
        this.makeToast(this.$t("enterdateofbirth"), "error");
        return false;
      }

      this.SearchLoading = true;
      PersonService.GetFromGovData(
        this.filter.docseries,
        this.filter.docnumber,
        this.filter.dateofbirth,
        this.filter.pinfl,
        this.filter.identitydocumentid
      )
        .then((res) => {
          this.Parent = res.data;
          this.PermanentAdress =
            res.data.result.CitizensInfoTables.filter(
              (item) => item.ispermanentaddress === true
            ).length > 0
              ? res.data.result.CitizensInfoTables.filter(
                  (item) => item.ispermanentaddress === true
                )[0]
              : {};
          this.ActiveAdress =
            res.data.result.CitizensInfoTables.filter(
              (item) => item.ispermanentaddress === false
            ).length > 0
              ? res.data.result.CitizensInfoTables.filter(
                  (item) => item.ispermanentaddress === false
                )[0]
              : {};
          this.SearchLoading = false;
        })
        .catch((error) => {
          this.SearchLoading = false;
          this.makeToast(error.response.data.error, "error");
        });
    },
    ChangeIdentityDocument() {
      if (this.filter.identitydocumentid == 4) {
        AccountService.GetParentForRegistration()
          .then((res) => {
            this.Parent = res.data.result;
          })
          .catch((error) => {
            this.makeToast(error.response.data.error, "error");
          });
        HelperService.GetAllOblast(this.lang).then((res) => {
          this.OblastList = res.data;
        });
      }
    },
    BindValue(data) {
      this.filter.dateofbirth = data;
    },
    GotoApplication() {
      var self = this;
      this.Parent.fullname =
        this.Parent.familyname +
        " " +
        this.Parent.firstname +
        " " +
        this.Parent.lastname;
      this.Parent.shortname =
        this.Parent.familyname +
        " " +
        this.Parent.firstname +
        " " +
        this.Parent.lastname;
      if (self.filter.identitydocumentid == 4) {
        if (
          this.filter.docseries === "" ||
          this.filter.docseries === 0 ||
          this.filter.docseries === undefined ||
          this.filter.docseries === null
        ) {
          this.makeToast(this.$t("enterdocumentseries"), "error");
          return false;
        }
        if (
          this.filter.docnumber === "" ||
          this.filter.docnumber === 0 ||
          this.filter.docnumber === undefined ||
          this.filter.docnumber === null
        ) {
          this.makeToast(this.$t("enterdocumentnumber"), "error");
          return false;
        }
        if (
          this.filter.dateofbirth === "" ||
          this.filter.dateofbirth === 0 ||
          this.filter.dateofbirth === undefined ||
          this.filter.dateofbirth === null
        ) {
          this.makeToast(this.$t("enterdateofbirth"), "error");
          return false;
        }

        this.Parent.identitydocumentid = this.filter.identitydocumentid;
        this.Parent.documentseries = this.filter.docseries;
        this.Parent.documentnumber = this.filter.docnumber;
        this.Parent.dateofbirth = this.filter.dateofbirth;
      }
      if (
        self.Parent.fullname === 0 ||
        self.Parent.fullname === "" ||
        self.Parent.fullname === null ||
        self.Parent.fullname === undefined
      ) {
        self.makeToast(self.$t("enterfullname"), "error");
        return false;
      }
      // if (
      //   this.Parent.CitizensInfoTables == null ||
      //   this.Parent.CitizensInfoTables.length == 0
      // ) {
      //   self.makeToast(this.$t("NotFillCitizenshipInfo"), "error");
      //   return false;
      // }
      this.SaveLoading = true;
      this.Parent.mobilenumber = this.$store.state.number;
      AccountService.InsertRegistration(this.Parent)
        .then((res) => {
          this.SaveLoading = false;
          localStorage.setItem("user_info", JSON.stringify(res.data.userinfo));
          this.$router.push({ name: "Cabinet" });
        })
        .catch((error) => {
          this.makeToast(error.response.data.error, "error");
          this.SaveLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
//   .my-img{
//     min-height: 100vh;
//     background: rgb(2,0,36);
// background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,15,125,1) 0%, rgba(104,104,176,1) 0%, rgba(3,250,251,0.13489145658263302) 96%);
// }
</style>
