
<template>
  <div>
      <label class="input-color-label" v-if="label !== ''"> {{ label }} </label>
      <div class="my-input" :class="{ 'no-label' : label === '','uppercase-text': uppertext }" >
          <div class="my-label-content">
              {{ content }}
          </div>
          <div class="right-addon-icon">
              <slot name="right-icon"></slot>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props:{
        label:{
            type : String,
            default : 'Label'
        },
        uppertext:{
            type : Boolean,
            default  :false
        },
        content  : {
            type : String,
            default : ''
        }
    },
    data(){
        return{
            // inputVal : ''
        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/_variables.scss';
.my-input{
    display: block;
    border-radius: 4px;
    width: 100%;
    background: linear-gradient(to right top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3));
    position: relative;
    .my-label-content{
        min-height: 33px;
        padding-bottom: 3px;
        padding-left: 15px;
        color: #fff;
        min-height: 50px;
        padding-top: 14px;
    }
    .right-addon-icon{
        position: absolute;
        top : 15px;
        right: 15px;
        vertical-align: middle;
        cursor: pointer;
    }
    label{
        width: 100%;
        padding-left: 16px;
        text-transform: uppercase;
        font-size: 12px;
        color: white;
    }
    input{
        width: 100%;
        outline: none;
        border: none;
        background: linear-gradient(to right top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .3));
        padding-left: 16px;
        border-radius: 12px;
        margin-bottom: 8px;
        color: #fff;
        &::placeholder{
            color: $input-text-placeholder;
        }
    }
    
}
.no-label{
    padding-top: 16px;
    padding-bottom: 8px;
    .input-color-label{
        display: none;
        color: #fff;
    }
}
.uppercase-text input{
    text-transform: uppercase;
}
.input-color-label{
        color: #fff;
    }
</style>